const colors = [
  '#d5dc3d',
  '#d3818b',
  '#ed4c28',
  '#fee64b',
  '#afd1da',
  '#fdc98d',
  '#52e337',
  '#ea3e2e',
  '#f59bf4',
  '#e2464a',
  '#f4b57d',
  '#c697e1',
  '#defafb',
  '#dccaff',
  '#f79cef',
  '#beff9c',
  '#f0f06f',
  '#8a68a5',
  '#7bf31c',
  '#ed84c0',
  '#ec1fe8',
  '#f790b1',
  '#fae500',
  '#e8dc29',
  '#fafa67',
  '#8eef8d',
  '#f47601',
  '#f3cf2c',
  '#29e583',
  '#c9c3ef',
  '#b6c523',
  '#259e6f',
  '#89e072',
  '#94cb03',
  '#e99655',
  '#f5d797',
  '#f2e7bf',
  '#d6e2d2',
  '#128c5a',
  '#ffb7fc',
  '#fd9205',
  '#ff8847',
  '#f3fc92',
  '#5ce75b',
  '#f9f23b',
  '#825af5',
  '#37b44e',
  '#a1d4ea',
  '#46eb06',
  '#cd6c05',
  '#85d99e',
  '#81ee73',
  '#41d4a4',
];

export default colors;
